import React from 'react'
import Login from './Login'

function EKVCLogin() {
    return (
        <>
            <Login account="EKVC" />
        </>
    )
}

export default EKVCLogin;