import React from 'react'
import Login from './Login'

function SEVCLogin() {
    return (
        <>
            <Login account="SEVC" />
        </>
    )
}

export default SEVCLogin;