import '../../css/about.css'
import { Layout } from 'antd'
import theme from '../../css/theme.json'
import React from 'react'
import Navbar from '../Navbar'
import { FiTarget } from "react-icons/fi"
import { GiBullseye, GiSupersonicBullet } from "react-icons/gi"
const { Content } = Layout
function About() {
    return (
        <Layout>
            <Navbar />
            <Content style={{ backgroundColor: theme.colors.backgroundColor, minHeight: "95vh" }} >

                <div className='about-main-container'>
                    <div className='title-2'>Solar Electric Vehicle Championship (SEVC)</div>
                    <div className='about-description'>
                        The core objective of SEVC has always been to cultivate an understanding among inquisitive minds regarding entry into 
                        the industrial domain. Its aim is to assist undergraduates in broadening their horizons in technical and practical realms, 
                        stimulating the prefrontal cortex to enhance problem-solving and planning skills, and augmenting managerial capabilities 
                        alongside core technical knowledge. The philosophy remains unaltered since the event's inception in 2019. Teams are tasked 
                        with constructing a simple e-vehicle designed for recreational purposes, prioritizing aesthetics and ergonomics while ensuring 
                        durability and enjoyment in real-world scenarios. . Across six editions, the competition has involved thousands of students and 
                        professionals within the engineering community. With its strategies revamped, the six editions redefine the landscape of competitive 
                        motorsports. Starting from this edition, we have introduced a new dynamic round known as the Autonomous round, featuring a driver-less,
                         self-driven vehicle. It is our pride to announce that SEVC is the pioneering motorsport event to introduce and implement a student-made
                          autonomous vehicle in India.
                    </div>
                </div>

                <div className='about-sub-container' >

                    <div className='img-about'>
                        <img src={require("../../assets/Final CSRM logo.png")} />
                    </div>
                    <div className='container-abt-text'>
                        <div className='title'>CSRM</div>
                        <div className='description'>The Coimbatore Society of Racing Minds, abbreviated as CSRM, is a duly registered Indian non-profit 
                        engineering and scientific society committed to the advancement of the e-mobility community within
                        India. CSRM was established with the primary objective of bridging the gap between academia and industry. It 
                        achieves this by orchestrating a myriad of design competitions and skill development programs tailored for
                        engineering students, facilitating the application of theoretical knowledge into practical endeavour's.

                        CSRM's arrray of design challenges, including the Solar Electric Vehicle Championship, Electric Kart Design Challenge
                        , and Electric Moped Challenge, furnishes a platform for aspiring engineers and diploma holders to chart 
                        their course towards technical and managerial proficiency. Through these initiatives, CSRM aims to cultivate
                        a fertile ground where individuals can nurture their talents and naviagate their journey towards excellence in
                        both technical and managerial realms. </div>
                    </div>
                </div>
                
                <div className="vision-container">
                    <div className='title-1'>
                        Our Mission <br />& Vision
                    </div>
                    <div className='container-mission'>
                        <div className='mission-container'>
                            <div className='mission-title'>Mission</div>
                            <div className='mission-description'>
                                <div> <GiSupersonicBullet /> Conduct events with innovative concepts satisfying society needs. Make them learn through
                                    practical approach. Create Opportunity for students to get placed in their desired fields.
                                </div>
                                <div>
                                    <GiSupersonicBullet /> To train and equip students with industrial level technical knowledge making them sound
                                    professionals enabling them to contribute to the development of our nation.
                                </div>
                            </div>
                        </div>
                        <div className='mission-container' >
                            <div className='mission-title'>Vision</div>
                            <div className='mission-description'>
                                <div><GiBullseye /> Educate, impart knowledge and create a multi-dimensional thinking for students to develop
                                    innovative concepts and make them excel in their domain.
                                </div>
                                <div>
                                    <GiBullseye /> To establish an eco-friendly means of transportation by the incorporation of future concept
                                    electric vehicles.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Content>
        </Layout>
    )
}

export default About
